<template>
  <v-app>
    <main v-if="!isTokenExpired">
      <v-img
        class="fill-height"
        :aspect-ratio="16 / 9"
        height="100vh"
        width="100vw"
      >
        <v-container fill-height>
          <v-row justify="center" align="center">
            <v-col cols="12" md="8" lg="6" xl="4">
              <v-card
                color="rgb(222 222 222 / 20%)"
                elevation="12"
                class="py-8"
              >
                <v-card-text class="text-center">
                  <h2 class="text-h4 black--text">
                    パスワードを再設定する
                  </h2>
                  <v-form
                    @submit.stop="submit"
                    action="javascript:void(0);"
                    v-model="isValid"
                  >
                    <div class="mt-6">
                      <v-row justify="center">
                        <v-col cols="12" md="10">
                          <v-text-field
                            v-model="email"
                            :rules="rules.email"
                            label="登録されたメールアドレス"
                            outlined
                            hide-details="auto"
                            class="mt-6"
                          ></v-text-field>

                          <v-text-field
                            v-model="new_password"
                            :rules="rules.new_password"
                            label="新しいパスワード"
                            outlined
                            prepend-inner-icon="mdi-lock-open"
                            :type="'password'"
                            hide-details="auto"
                            class="mt-6"
                          ></v-text-field>

                          <v-text-field
                            v-model="confirm_password"
                            :rules="rules.confirm_password"
                            label="パスワード確認"
                            outlined
                            prepend-inner-icon="mdi-lock-open"
                            :type="showPassword ? 'text' : 'password'"
                            :append-icon="
                              showPassword ? 'mdi-eye' : 'mdi-eye-off'
                            "
                            @click:append="showPassword = !showPassword"
                            hide-details="auto"
                            class="mt-6"
                          ></v-text-field>

                          <div
                            class="d-flex mt-2"
                            v-if="inValidMsg.message != ''"
                          >
                            <span
                              :class="
                                inValidMsg.status ? 'green--text' : 'red--text'
                              "
                              >{{ inValidMsg.message }}</span
                            >
                          </div>

                          <div class="mt-6">
                            <v-btn
                              block
                              class="text-capitalize"
                              large
                              @click="submit"
                              color="primary"
                              :loading="loading"
                              :disabled="!isValid"
                              >リセット</v-btn
                            >
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </main>
    <main v-else-if="isTokenExpired">
      <v-card :loading="loading">
        <v-img
          class="fill-height"
          :aspect-ratio="16 / 9"
          height="100vh"
          width="100vw"
        >
          <v-container fill-height v-if="!loading">
            <v-row justify="center" align="center">
              <v-col cols="12" md="6" class="text-center">
                <v-img
                  :src="siteAppURL + '/images/logo.png'"
                  width="200px"
                  class="mx-auto"
                >
                </v-img>
                <br /><br /><br />
                <p class="mt-5 pt-5">
                  こちらのリンクの有効期限が切れています。最初からやり直してください。
                </p>
                <v-btn text color="primary" :to="{ name: 'Login' }"
                  >ログイン画面に戻る</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-img>
      </v-card>
    </main>
  </v-app>
</template>

<script>
import api from '@/services/api-methods'
export default {
  name: 'ResetPassword',
  data() {
    return {
      loading: false,
      inValidMsg: {},
      showPassword: false,
      new_password: null,
      confirm_password: null,
      email: null,
      token: this.$route.params.token,
      isValid: true,
      isTokenExpired: false,
      siteAppURL: process.env.VUE_APP_URL,
      rules: {
        email: [
          v => !!v || 'メールアドレスが必要です。',
          v =>
            /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            'メールアドレスを正しくご入力ください。'
        ],
        new_password: [
          v => !!v || '新しいパスワードが必要です。',
          v =>
            (v && v.length >= 6) ||
            'パスワードは6文字以上にする必要があります。'
        ],
        confirm_password: [
          v => !!v || 'パスワード確認が必要です。',
          v =>
            (v && v.length >= 6) ||
            'パスワードは6文字以上にする必要があります。',
          v =>
            v === this.new_password ||
            'パスワードが一致しません。もう一回入力してください。'
        ]
      }
    }
  },

  beforeMount() {
    this.checkExpiredToken()
  },

  methods: {
    submit() {
      this.inValidMsg = {}
      this.loading = true
      let postData = {
        password: this.new_password,
        token: this.token,
        password_confirmation: this.confirm_password,
        email: this.email
      }
      api
        .post('/reset-password', postData)
        .then(res => {
          let response = res.data
          this.loading = false
          this
          this.inValidMsg = {
            status: response.status,
            message: response.message
          }
          if (response.status) {
            this.$router.push({ name: 'Login' })
          }
        })
        .catch(err => {
          this.$swal.fire('アクションなし！', err, 'error')
          this.loading = false
          this.inValidMsg = {
            status: false,
            message: 'このメールアドレスは登録されていません。'
          }
        })
    },
    checkExpiredToken() {
      this.isTokenExpired = true
      let queyrEmail = this.$route.query.email
      this.loading = true
      console.log(this.email)
      api
        .get('/check-reset-password/' + this.token, { email: queyrEmail })
        .then(res => {
          let response = res.data
          this.loading = false
          this.isTokenExpired = false
          if (response.status == 'failed') {
            this.isTokenExpired = true
          }
          console.log(response)
        })
        .catch(err => {
          this.$swal.fire('アクションなし！', err, 'error')
          this.loading = false
          this.isTokenExpired = true
        })
    }
  }
}
</script>
<style></style>
